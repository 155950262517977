<template>
  <div v-if="!api" class="placeholder">
    <img src="https://bvg-cdn.de/logos/bvg-herz_outline.svg" alt="BVG Logo" />
  </div>
  <div v-else class="container">
    <main>
      <section v-for="(chunks, ii) in getTrafficData" :key="ii">
        <LinienBox v-for="(data, jj) in chunks" :key="jj" :data="data" />
      </section>
    </main>
  </div>

  <footer>
    <div class="sysinfo">
      <span
        >&copy; {{ new Date().getFullYear() }} Berliner Verkehrsbetriebe
        (BVG)</span
      >
      <span>Version {{ APP_VERSION }}</span>
      <span v-if="api">{{ api.datetime }}</span>
    </div>
    <div v-if="clockElement" id="clock">{{ clockElement }}</div>
  </footer>
</template>

<script>
import LinienBox from './components/LinienBox.vue';
// import QRCodeBox from './components/QRCodeBox.vue';

export default {
  components: {
    LinienBox,
    // QRCodeBox,
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      APP_VERSION: __APP_VERSION__,
      timer: null,
      clockElement: null,
      api: null,
      errorReport: null,
    };
  },
  computed: {
    getTrafficData() {
      const chunks = new Map();

      const data = Object.values(this.api.data);
      data.forEach((d) => {
        if (!chunks.has(d.profile)) {
          chunks.set(d.profile, []);
        }
        chunks.get(d.profile).push(d);
      });

      return Array.from(chunks.values());
    },
  },
  mounted() {
    // this.clock();
    this.fetchData();
    this.timer = setInterval(this.fetchData, 5 * 60 * 1000);
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
  methods: {
    async fetchData() {
      const apis = [
        'https://api.bvg-fgi.de/static/traffic-symbols-v2.json',
        'https://api.bvg-fgi.de/static/disruptions.json',
      ];

      try {
        const [apiTraffic, apiDisruption] = await Promise.all(
          apis.map(async (api) => {
            const response = await fetch(api);
            if (!response.ok) {
              throw new Error(`Failed to fetch: ${api}`);
            }
            return response.json();
          })
        );

        Object.values(apiTraffic.data).forEach((t) => {
          const lowerLine = t.lineTxt.toLowerCase();
          t.hasDisruption = apiDisruption.some(
            (d) =>
              d.line === lowerLine && d.type !== null && d.type !== 'Kein Halt'
          );
        });

        this.api = apiTraffic;
      } catch (err) {
        this.errorReport = err;
        console.error(err);
      }
    },
    clock() {
      const date = new Date();
      let h = date.getHours();
      let m = date.getMinutes();

      h = h < 10 ? `0${h}` : h;
      m = m < 10 ? `0${m}` : m;

      this.clockElement = `${h}:${m}`;

      setTimeout(this.clock, 1000);
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style scoped>
.placeholder,
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.placeholder {
  background-color: var(--bvg-color);
}

.placeholder > img {
  max-height: 60%;
  max-width: 60%;
}

main {
  display: flex;
  /* gap: var(--grid-vertical); */
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}
main > section {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  gap: var(--grid-horizontal);
  padding: 1rem;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;

  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
footer > .sysinfo {
  display: flex;
  gap: 0.5rem;
  font-size: 0.35rem;
  padding: 0.15rem;
  color: rgba(0, 0, 0, 0.3);
}
footer > #clock {
  line-height: 0.85;
  font-weight: 600;
  font-size: var(--footer-size);
  padding: 0 var(--footer-right) var(--footer-bottom) 0;
}

@media (max-width: 800px) {
  .container {
    display: block;
    height: auto;
  }

  main {
    padding: 1rem 0;
    /* gap: 1rem; */
  }

  main > section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0.5rem;
  }

  footer {
    position: static;
  }
}
</style>
